export const sidebarMenuOptions = (t, handleRedirectLink, profile) => {
  const isAdmin = profile?.userRoles?.some(role => 
    role === "Administrator"
  ) || false;
  const isPowerUser = profile?.userRoles?.some(role => 
    role === "PowerUser"
  ) || false;

  if (profile?.loading) {
    return [];
  }

  return [
    {
      id: 17,
      link: "/dashboard",
      clickHandler: handleRedirectLink,
      group: "Dashboard",
      classNames: "waves-effect",
      iconClassName: "ri-dashboard-fill",
      displayName: t("Dashboard"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 0,
      link: "/assetinsight",
      clickHandler: handleRedirectLink,
      group: "ApplicationIntegration",
      classNames: "waves-effect",
      iconClassName: "ri-file-list-3-fill",
      displayName: t("Asset Insight"),
      isVisible: true,
      expand: false,
      isActive: false,
    },
    {
      id: 14,
      link: "#",
      group: "cloudSpend",
      clickHandler: handleRedirectLink,
      classNames: "waves-effect",
      iconClassName: "ri-money-dollar-circle-fill",
      displayName: t("Cost Spend"),
      isVisible: true,
      expand: false,
      isActive: false,
      subMenu: [
        {
          id: 14,
          link: "/costinsight",
          group: "costInsight",
          clickHandler: handleRedirectLink,
          classNames: "waves-effect",
          displayName: t("Cost Insight"),
          isVisible: true,
          isActive: false,
          isSubmenu: true,
        },
        {
          id: 14,
          link: "/SpendAllocation",
          group: "SpendAllocation",
          clickHandler: handleRedirectLink,
          classNames: "waves-effect",
          displayName: t("Spend Allocation"),
          isVisible: true,
          isActive: false,
          isSubmenu: true,
        },
      ]
    },
    {
      id: 15,
      link: "/costoptimization",
      group: "costOptimization",
      clickHandler: handleRedirectLink,
      classNames: "waves-effect",
      iconClassName: "ri-money-dollar-circle-fill",
      displayName: t("Cost Optimization"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 11,
      link: "/eventinsight",
      clickHandler: handleRedirectLink,
      group: "eventInsight",
      classNames: "waves-effect",
      iconClassName: "ri-pie-chart-fill",
      displayName: t("Event Insight"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 10,
      link: "/Security",
      clickHandler: handleRedirectLink,
      group: "Security",
      classNames: "waves-effect",
      iconClassName: "ri-shield-keyhole-line",
      displayName: t("Security Posture"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 12,
      link: "/Compliance",
      clickHandler: handleRedirectLink,
      classNames: "waves-effect",
      group: "Compliance",
      iconClassName: "ri-todo-line",
      displayName: t("Compliance"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 17,
      link: "/iacmanagement/dashboard",
      clickHandler: handleRedirectLink,
      group: "IACManagement",
      classNames: "waves-effect",
      iconClassName: "ri-command-fill",
      displayName: t("IaC Scanning"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 11,
      link: "/rules",
      clickHandler: handleRedirectLink,
      group: "alerts",
      classNames: "waves-effect",
      iconClassName: "ri-notification-line",
      displayName: t("Rules & Alerts"),
      isVisible: isAdmin || isPowerUser,
      isActive: false,
    },
    {
      id: 16,
      link: "/configuration/accounts",
      clickHandler: handleRedirectLink,
      group: "configuration",
      classNames: "waves-effect",
      iconClassName: "ri-settings-4-line",
      displayName: t("Configuration"),
      isVisible: true,
      isActive: false,
    },
    {
      id: 18,
      link: "/supportrequest",
      clickHandler: handleRedirectLink,
      group: "Support",
      classNames: "waves-effect",
      iconClassName: "ri-apps-2-fill",
      displayName: t("Support"),
      isVisible: true,
      expand: false,
      isActive: false,
      subMenu: [
        {
          id: 181,
          link: "/supportrequest",
          clickHandler: handleRedirectLink,
          group: "Support",
          classNames: "waves-effect",
          iconClassName: "ri-mail-send-line",
          displayName: t("Create Support Case"),
          isVisible: true,
          isSubmenu: true,
          isActive: false,
        },
        {
          id: 182,
          link: "/releasenotes",
          clickHandler: handleRedirectLink,
          group: "Support",
          classNames: "waves-effect",
          iconClassName: "ri-file-list-3-fill",
          displayName: t("Release Notes"),
          isVisible: true,
          isSubmenu: true,
          isActive: false,
        },
        {
          id: 183,
          link: "/user-manual",
          clickHandler: handleRedirectLink,
          group: "Support",
          classNames: "waves-effect",
          iconClassName: "ri-file-list-3-fill",
          displayName: t("User Manual"),
          isVisible: true,
          isSubmenu: true,
          isActive: false,
        },
        {
          id: 183,
          link: "/faq",
          clickHandler: handleRedirectLink,
          group: "Support",
          classNames: "waves-effect",
          iconClassName: "ri-file-list-3-fill",
          displayName: t("FAQ"),
          isVisible: true,
          isSubmenu: true,
          isActive: false,
        },
      ],
    },
  ];
};
