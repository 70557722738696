import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";

// Main Cost Optimization Dashboard
const CostOptimizationDashboard = () => {
  const [activeTab, setActiveTab] = useState("costoptimization");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "costoptimization":
        return <GetKibanaDashboard dashboardKey={"CostOptimizationhub"} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="COST Optimization" value="costoptimization" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default CostOptimizationDashboard;
