import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { Row, Col, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DataGrid from "../DataGrid";
import ViewAccountList from "./ViewAccountList.js";
import UpdateAccount from "./UpdateAccount.js";
import OrgAccount from "./OrgAccount.js";
import { convertDate } from "../../helpers/util_helper";
import {
  getListOfAccts,
  clearAllOrgAccountMessages,
  getPricingInfo,
  clearAllAwsAccMessages,
} from "../../store/actions";
import { clearAllAccountConfigurationMessages } from "../../store/accountConfiguration/accountConfigurationSlice";
import { connect } from "react-redux";
import AddOnsManagement from "./AddonsManagement";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { enqueueSnackbar } from "notistack";

import UpdateIamRole from "./UpdateIamRole.js";
import getUserRole from "./../../helpers/jwt-token-access/userRole.js";
import SubscriptionModal from "./SubscriptionManagement/SubscriptionModal.js";
import { Tooltip, IconButton, Typography, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import styles from "./style.module.css";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const allowedRoles = ["Administrator"];

const activeColor = "#4caf50"; // Green
const inactiveColor = "#f44336"; // Red
const disabledAddonsColor = "#5f9ed1"; // Blue
const expiredColor = "#7a0709"; // Brown

const InactiveAccountIcon = () => (
  <Tooltip title="Activate Account" placement="top">
    <IconButton size="small" className={styles.lockButton}>
      <LockOpenIcon fontSize="small" />
    </IconButton>
  </Tooltip>
);

const LegendItem = ({ color, description }) => (
  <Box display="flex" alignItems="center" mb={1}>
    <FiberManualRecordIcon
      sx={{ color: color, marginRight: 1, fontSize: 12 }}
    />
    <Typography variant="body2">{description}</Typography>
  </Box>
);

const Legends = () => {
  return (
    <Box sx={{ marginTop: "1rem" }}>
      <LegendItem
        color={activeColor}
        description="Active Account: All modules (Event Insight, Cost Insight, Cost Optimization) enabled"
      />
      <LegendItem
        color={inactiveColor}
        description={
          <>
            Inactive Account: Account inactive due to incorrect External ID or
            cross-account role missing. Please click on the{" "}
            <LockOpenIcon sx={{ color: "orange" }} fontSize="small" /> and
            follow the account pairing guide.
          </>
        }
      />
      <LegendItem
        color={disabledAddonsColor}
        description="Active Account: One or more modules (Event Insight, Cost Insight, Cost Optimization) disabled"
      />
      <LegendItem
        color={expiredColor}
        description="Subscription Expired: Please renew your subscription to enable features."
      />
    </Box>
  );
};

class DataGridWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    //Working
    // Re-render if new account Added
    if (this.props.data.rows.length !== nextProps.data.rows.length) {
      return true; // Allow the component to update if 'data' prop has changed
    }
    // console.log("shouldComponentUpdate, nextProps", nextProps);
    // console.log("shouldComponentUpdate, this.props.data", this.props.data);

    for (const prevAcct of this.props.data.rows) {
      const nextAcct = nextProps.data.rows.find(
        (acct) => acct.accountCode === prevAcct.accountCode
      );
      console.log("shouldComponentUpdate, nextAcct : ", nextAcct);
      if (prevAcct.last_modified !== nextAcct.last_modified) {
        return true;
      }
    }

    return false; // Prevent the component from updating
    //following is for testing
    // return true; // Prevent the component from updating
  }

  render() {
    return (
      <>
        <DataGrid {...this.props} />
      </>
    );
  }
}

class AccountDetailPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      modal_static: false,
      modal_addons: false,
      editAcctModal: false,
      editRoleModal: false,
      notifyDelete: false,
      isOpen: false,
      tooltipOpen: false,
      numAccounts: this.props.licensing.numAccounts,
      isLicensingInfoOpen: false, // to open LicensingInfo modal
      selectedLicense: null, // to be used in LicensingInfo modal
      accountInformation: null, // to be used in LicensingInfo modal
      licenseData: null,
      accounts: null,
      editAccountCode: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { ...prevState, numAccounts: nextProps.licensing.numAccounts };
  }

  componentDidMount() {
    document
      .getElementsByClassName("pagination")[0]
      .classList.add("pagination-rounded");
    const [searchBoxItem, dataTableRef] =
      document.getElementsByClassName("mdb-datatable")[0].children;
    if (
      searchBoxItem.getElementsByClassName("mdb-datatable-filter").length > 0
    ) {
      searchBoxItem.setAttribute(
        "style",
        "justify-content: flex-end !important"
      );

      const [dataTableTHead] = dataTableRef.getElementsByTagName("thead");
      dataTableTHead.setAttribute(
        "style",
        "color: #fff !important; background-color: #343a40 !important;"
      );
      const reactDynamicTag = document.createElement("div");
      reactDynamicTag.setAttribute("id", "userConfigBtnHolder");
      reactDynamicTag.setAttribute("class", "pt-1");
      searchBoxItem.append(reactDynamicTag);

      const userRole = getUserRole();
      ReactDOM.render(
        this.getAddButton(userRole),
        document.getElementById("userConfigBtnHolder")
      );
    }
  }

  componentDidUpdate(prevProps) {
    const userRole = getUserRole();
    ReactDOM.render(
      this.getAddButton(userRole),
      document.getElementById("userConfigBtnHolder")
    );
    if (prevProps.accounts !== this.props.accounts) {
      this.setState({ accounts: this.props.accounts });
    }
    if (this.props.pricingInfo && !this.state.licenseData) {
      this.setState({
        licenseData: this.props.pricingInfo["Subscription Model"],
      });
    }

    // //Display notification
    // if(prevProps.postAwsAccMessage !== this.props.postAwsAccMessage && this.props.postAwsAccMessage) {
    //   console.log("this.props.postAwsAccMessage : ", this.props.postAwsAccMessage);
    //   enqueueSnackbar(this.props.postAwsAccMessage, { variant: "success" });
    //   this.props.clearAllAwsAccMessages();
    // }
    // if(prevProps.postAwsAccError !== this.props.postAwsAccError && this.props.postAwsAccError) {
    //   enqueueSnackbar(this.props.postAwsAccError, { variant: "error" });
    //   this.props.clearAllAwsAccMessages();
    // }
  }

  getAddButton = (userRole) => {
    const filtered =
      this.props.accounts &&
      this.props.accounts.filter(
        (x) =>
          !x.accountNumber.includes("INIT00000001") &&
          !x.accountNumber.includes("TEST") &&
          x.accountNumber.length === 12
      );
    if (
      this.props.orgLicensing?.data?.licenseName !== "AwsMarketPlaceLicense"
    ) {
      return (
        <div className="ml-2" style={{ marginRight: 10 }}>
          <Button
            style={{
              textAlign: "right",
              backgroundColor: "#F98125",
              float: "right",
              borderColor: "#fff",
            }}
            size="sm"
            onClick={this.openModal}
            className={`btn mb-2 ${
              this.props.roleAccess >= 0 ? " btn-success" : ""
            }`}
            disabled={
              this.props.roleAccess < 0 ||
              filtered.length >= this.state.numAccounts ||
              !allowedRoles.includes(userRole)
            }
          >
            <span id="AddAccountsBtn">
              <i className="mdi mdi-plus mr-2"></i>Add Accounts
            </span>
          </Button>
        </div>
      );
    }
  };

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        this.setState({ ...this.state, activeTab: tab });
      }
    }
  };

  setNotifyDelete(status, row) {
    this.setState({
      ...this.state,
      notifyDelete: status,
      notifyDeleteRow: row,
    });
  }

  newAccountAdded = (receivedState) => {
    if (
      receivedState &&
      Array.isArray(receivedState) &&
      receivedState.length > 0
    ) {
      receivedState = receivedState.filter(
        (x) =>
          !x.accountNumber.includes("INIT00000001") &&
          !x.accountNumber.includes("TEST") &&
          x.accountNumber.length === 12
      );
      const receivedStateLength = receivedState.length;
      if (this.props.accounts.length !== receivedStateLength) {
        this.setState({ accounts: this.props.accounts });
        console.log(
          "Calling getListOfAccts() from AccountDetailPolicy, newAccountAdded"
        );
        this.props.dispatchGetListOfAccts();
      }
    } else {
      console.error("receivedState is either null, not an array, or empty");
    }
  };

  updateData = () => {
    console.log("Update Data Called ***");
    if (this.props.postAccountConfigurationMessage) {
      console.log(
        "Calling getListOfAccts() from AccountDetailPolicy, updateData"
      );
      this.props.dispatchGetListOfAccts();
    }
    if (
      this.props.postAccountConfigurationMessage ||
      this.props.postAccountConfigurationError
    ) {
      this.props.clearAllAccountConfigurationMessages();
    }
  };

  closeModal = () => {
    console.log("Close Account Modal Called ***");
    this.setState({
      ...this.state,
      modal_static: false,
      modal_addons: false,
      editAcctModal: false,
      editRoleModal: false,
      isOpen: false,
    });
    // this.props.dispatchGetListOfAccts();
    this.props.clearAllOrgAccountMessages();
  };

  openModal = () => this.setState({ isOpen: true });

  closeModalview = () => this.setState({ isOpen: false });
  setTooltipOpen = (state) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        tooltipOpen: state,
      };
    });
  };

  openLicensingInfoModal = (selectedLicense, accountInformation) => {
    this.setState({
      isLicensingInfoOpen: true,
      selectedLicense: selectedLicense,
      accountInformation: accountInformation,
    });
  };
  closeLicensingInfoModal = () => {
    this.setState({
      isLicensingInfoOpen: false,
      licenseData: null,
      selectedLicense: null,
      accountInformation: null,
    });
  };

  handleUpgradeClick = (successMessage) => {
    enqueueSnackbar(successMessage, { variant: "success" });
    console.log(
      "Calling getListOfAccts() from AccountDetailPolicy, handleUpgradeClick"
    );
    this.props.dispatchGetListOfAccts();
    this.setState({ accounts: this.props.accounts });
  };

  handleAddonsUpgrade = (successMessage) => {
    console.log("Upgrade process initiated with Addons:");
    console.log("Before enqueueSnackbar");
    enqueueSnackbar(successMessage, { variant: "success" });
    console.log("After enqueueSnackbar");
    console.log(
      "Calling getListOfAccts() from AccountDetailPolicy, handleAddonsUpgrade"
    );
    this.props.dispatchGetListOfAccts();
    this.setState({ accounts: this.props.accounts });
    console.log("After Update");
  };

  getAccountStatusColor = (acct) => {
    if (acct.status === "ACTIVE" && acct.subscription_status !== "expired") {
      return acct.subscription?.addons === null ||
        acct.subscription?.addons?.length < 3 ||
        acct.subscription?.addons?.filter((addon) => addon.enabled).length < 3
        ? disabledAddonsColor
        : activeColor;
    } else if (acct.subscription_status === "expired") {
      return expiredColor;
    } else if (acct.status === "INACTIVE") {
      return inactiveColor;
    }
  };

  render() {
    console.log("AccountDetailPolicy is rendered");
    const userRole = getUserRole();
    const filtered = this.props.accounts;
    const editAcct = this.props.accounts.find(
      (account) => account.accountCode === this.state.editAccountCode
    );
    const complianceAddOns = this.props.accounts.find(
      (account) => account.accountCode === this.state.editAccountCode
    )?.complianceAddOns;
    const addOns = this.props.accounts.find(
      (account) => account.accountCode === this.state.editAccountCode
    )?.subscription?.addons;

    const data = {
      columns: [
        {
          label: "",
          field: " ",
          sort: "disabled",
        },
        {
          label: "Cloud Accounts",
          field: "id",
          sort: "asc",
        },
        {
          label: "Account Code",
          field: "accountCode",
          sort: "asc",
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        // {
        //   label: "Type",
        //   field: "type",
        //   sort: "asc",
        // },
        {
          label: (
            <Box display="flex" alignItems="center">
              Subscription
              <Tooltip
                title="Click on a subscription below to view details"
                placement="top"
              >
                <IconButton size="small" style={{ color: "white" }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          ),
          field: "subscription",
          sort: "asc",
        },
        {
          label: (
            <div>
              Add-Ons
              <Tooltip title="Click on the Add-Ons below to view or manage them">
                <IconButton size="small" style={{ color: "white" }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          ),
          field: "addons",
          sort: "disabled",
        },
        {
          label: (
            <div>
              Users
              <Tooltip title="Click below to see the users who have access to the selected account">
                <IconButton
                  size="small"
                  style={{ marginLeft: "5px", color: "white" }}
                >
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          ),
          field: "user",
          sort: "disabled",
        },
        {
          label: "Resource Group",
          field: "group",
          sort: "asc",
        },
        {
          label: "Date Added",
          field: "date_added",
          sort: "asc",
        },
        {
          label: "Date Modified",
          field: "date_modified",
          sort: "asc",
        },
        {
          label: "Action",
          field: "action",
          sort: "disabled",
          className: "action-column",
        },
      ],
      rows:
        filtered &&
        filtered.map((acct) => {
          return {
            id: (
              <Box display="flex" alignItems="center">
                {acct.accountNumber}
                <FiberManualRecordIcon
                  sx={{
                    color: this.getAccountStatusColor(acct),
                    fontSize: 12, // Adjust size to fit your design
                  }}
                />
              </Box>
            ),
            accountCode: `${acct.accountCode}`,
            name: `${acct.accountName}`,
            type: `${acct.accounttype}`,
            group: `${acct.accountResourceGroups}`
              ? `${acct.accountResourceGroups[0].resourcename}`
              : "Default",
            user: (
              <Link
                to="#"
                // className="btn btn-link mr-3 text-darkblue"
                onClick={() =>
                  this.setState({
                    ...this.state,
                    modal_static: true,
                    accountCode: acct.accountCode,
                    accountNumber: acct.accountNumber,
                    userList: acct.listusr.filter((user) => user.isActivated),
                  })
                }
              >
                <div className="badge badge-pill badge-light">
                  {
                    acct.listusr.filter(
                      (user) =>
                        user.isActivated &&
                        !user.email.includes(process.env.REACT_APP_EMAIL_DOMAIN)
                    ).length
                  }
                </div>
              </Link>
            ),
            addons: (
              <div>
                {acct.status === "ACTIVE" ? (
                  <Link
                    to="#"
                    onClick={() => {
                      // console.log("~~~Account = ", acct)
                      this.setState({
                        ...this.state,
                        modal_addons: true,
                        accountCode: acct.accountCode,
                        accountNumber: acct.accountNumber,
                        editAccountCode: acct.accountCode,
                      });
                    }}
                  >
                    <div className="badge badge-pill badge-light">
                      {acct.complianceAddOns.filter((addOn) => addOn.enabled)
                        .length +
                        acct.subscription.addons.filter(
                          (addOn) => addOn.enabled
                        ).length}
                    </div>
                  </Link>
                ) : (
                  <div>
                    <div className="badge badge-pill badge-light">
                      {acct.complianceAddOns.filter((addOn) => addOn.enabled)
                        .length +
                        acct.subscription.addons.filter(
                          (addOn) => addOn.enabled
                        ).length}
                    </div>
                  </div>
                )}
              </div>
            ),
            date_added: convertDate(
              acct.subscription_modified_date
                ? acct.subscription_modified_date
                : acct.createdDate
            ),
            date_modified: convertDate(`${acct.lastModifiedDate}`),
            last_modified: `${acct.lastModifiedDate}`,
            subscription: (
              <div>
                <Link
                  to="#"
                  onClick={() => {
                    this.openLicensingInfoModal(acct.subscription.name, acct);
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>
                    {acct.subscription_type}
                  </div>
                </Link>
              </div>
            ),
            action: (
              <div
                className="d-flex justify-content-around"
                style={{ width: 100 }}
              >
                <div>
                  {this.props.roleAccess < 0 ||
                  !allowedRoles.includes(userRole) ||
                  this.props.licensing.licenseName ===
                    process.env.REACT_APP_FREE_ORGANIZATION_LICENSE ||
                  acct.subscription_status === "expired" ? (
                    <button
                      disabled
                      className="btn btn-link text-info btn-sm"
                      id="edit1"
                    >
                      <i className="mdi mdi-quadcopter font-size-18 text-darkblue"></i>
                    </button>
                  ) : (
                    <>
                      <Link
                        to="#"
                        // className="btn btn-link mr-3 text-darkblue"
                        id="edit1"
                        title={
                          acct.status === "INACTIVE"
                            ? "Activate Account"
                            : "Rotate External ID"
                        }
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            editAccountCode: acct.accountCode,
                            editRoleModal: true,
                          })
                        }
                      >
                        {acct.status === "INACTIVE" && acct.iamrole == null  ? (
                          <Button
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: "0",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <InactiveAccountIcon />
                          </Button>
                        ) : (
                          <i className="mdi mdi-quadcopter font-size-18 text-darkblue"></i>
                        )}
                      </Link>
                    </>
                  )}
                </div>
                <div>
                  {this.props.roleAccess < 0 ||
                  !allowedRoles.includes(userRole) ||
                  this.props.licensing.licenseName ===
                    process.env.REACT_APP_FREE_ORGANIZATION_LICENSE ||
                  acct.subscription_status === "expired" ||
                  acct.status !== "ACTIVE" ? (
                    <button
                      disabled
                      className="btn btn-link text-info btn-sm"
                      id="edit1"
                    >
                      <i className="mdi mdi-pencil font-size-18 text-darkblue"></i>
                    </button>
                  ) : (
                    <>
                      <Link
                        to="#"
                        // className="btn btn-link mr-3 text-darkblue"
                        id="edit1"
                        title="Update Account"
                        onClick={() =>
                          this.setState({
                            ...this.state,
                            editAcctModal: true,
                            editAccountCode: acct.accountCode,
                          })
                        }
                      >
                        <i className="mdi mdi-pencil font-size-18 text-darkblue"></i>
                      </Link>
                    </>
                  )}
                </div>
                <div>
                  {this.props.roleAccess < 0 ||
                  !allowedRoles.includes(userRole) ||
                  acct.subscription_status === "expired" ||
                  acct.status !== "ACTIVE" ? (
                    <button
                      disabled
                      className="btn btn-link text-info btn-sm"
                      id="delete1"
                      title="Delete Account"
                    >
                      <i className="mdi mdi-trash-can font-size-18 text-danger"></i>
                    </button>
                  ) : (
                    <>
                      <Link
                        to="#"
                        // className="btn btn-link text-danger"
                        id="delete1"
                        title="Delete Account"
                        disabled={this.props.roleAccess < 0}
                        onClick={() => this.setNotifyDelete(true, acct)}
                      >
                        <i className="mdi mdi-trash-can font-size-18 text-danger"></i>
                      </Link>
                    </>
                  )}
                </div>
              </div>
            ),
          };
        }),
    };
    return (
      <React.Fragment>
        {this.state.isOpen && (
          <Modal isOpen={this.state.isOpen} size="lg" centered>
            <ModalHeader
              toggle={this.closeModal}
              style={{ backgroundColor: "#F98125", margin: -1 }}
            ></ModalHeader>
            <ModalBody>
              <OrgAccount
                handleAddAccountsModal={this.closeModal}
                newAccountAdded={this.newAccountAdded}
              />
            </ModalBody>
          </Modal>
        )}
        <Row>
          <Col lg={12}>
            <div style={{ overflowX: "auto", maxHeight: "400px" }}>
              <DataGridWrapper
                searchTop
                searchBottom={false}
                hover={true}
                responsive={true}
                small
                entriesOptions={[10, 20, 30]}
                data={data}
                fullPagination
              />
            </div>
            <Legends />
          </Col>
        </Row>
        {this.state.modal_static && (
          <ViewAccountList
            modal_static={this.state.modal_static}
            closeModal={this.closeModal}
            accountCode={this.state.accountCode}
            accountNumber={this.state.accountNumber}
            userList={this.state.userList}
          />
        )}

        {this.state.modal_addons && (
          <AddOnsManagement
            modal_addons={this.state.modal_addons}
            closeModal={this.closeModal}
            // accountCode={this.state.accountCode}
            // accountNumber={this.state.accountNumber}
            complianceAddOns={complianceAddOns}
            addOns={addOns}
            editAccount={editAcct}
            handleAddonsUpgrade={this.handleAddonsUpgrade}
          />
        )}

        {/* Rotate IAM Role */}
        <Modal isOpen={this.state.editRoleModal} size="lg" centered>
          <ModalHeader
            style={{ backgroundColor: "#F98125", margin: -1}}
          >
           <Typography color="#FFFFFF" variant="h6">
            {editAcct?.status === "ACTIVE" ? "Update IAM Role" : "Activate Account"}
          </Typography>
          </ModalHeader>
          <ModalBody>
            <UpdateIamRole
              selectedAccount={editAcct}
              addOns={addOns}
              closeModal={this.closeModal}
              key={editAcct?.accountCode}
            />
          </ModalBody>
        </Modal>
        {/* Update Account */}
        <Modal isOpen={this.state.editAcctModal} size="lg" centered>
          <ModalHeader
            toggle={this.closeModal}
            style={{ backgroundColor: "#F98125", margin: -1 }}
          >
            <Typography color="#FFFFFF" variant="h6">
                Update Account
            </Typography>
          </ModalHeader>
          <ModalBody>
            <UpdateAccount
              selectedAccount={editAcct}
              closeModal={this.closeModal}
              updateData={this.updateData}
            />
          </ModalBody>
        </Modal>
        {/* Delete account */}
        <Modal isOpen={this.state.notifyDelete} size="md" centered>
          <ModalHeader
            closeModal={this.closeModal}
            style={{ backgroundColor: "#F98125", margin: -1 }}
          >
            <Typography color="#FFFFFF" variant="h6">
              Delete Account
            </Typography>  
          </ModalHeader>
          <ModalBody>
            {this.state.notifyDeleteRow && (
              <>
                {[
                  { name: "Account Name", field: "accountName" },
                  { name: "Account Number", field: "accountNumber" },
                ].map((item) => {
                  return (
                    <div className="form-group row">
                      <label
                        htmlFor="isLicComplete"
                        className="col-sm-6 col-form-label"
                      >
                        {item.name}
                      </label>
                      <div className="col-sm-5">
                        {this.state.notifyDeleteRow[item.field]}
                      </div>
                    </div>
                  );
                })}
                Customer-enabled deletion is not available at the moment. Please
                email{" "}
                <a href="mailto:support.cloudcatcher@cloudnovatech.com">
                  support.cloudcatcher@cloudnovatech.com
                </a>{" "}
                to request deletion of this account.
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn w-lg btn-secondary"
              onClick={() => this.setNotifyDelete(false)}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
        {/* Modal to display Licensing Information */}
        <SubscriptionModal
          isOpen={this.state.isLicensingInfoOpen}
          toggle={this.closeLicensingInfoModal}
          handleUpgradeClick={this.handleUpgradeClick}
          accountInformation={this.state.accountInformation}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    pricingInfo,
    error: pricingInfoApiError,
    loading: pricingInfoApiLoading,
    accountNumber,
  } = state.pricingInfo;

  const { organizations } = state.acctList;

  const { postAccountConfigurationMessage, postAccountConfigurationError } =
    state.accountConfiguration;

  const { awsAccountPosting, postAwsAccMessage, postAwsAccError } =
    state.awsAcc;

  return {
    pricingInfo,
    pricingInfoApiError,
    pricingInfoApiLoading,
    accountNumber,
    organizations,
    orgLicensing: state.licensing,
    postAccountConfigurationMessage,
    postAccountConfigurationError,

    awsAccountPosting,
    postAwsAccMessage,
    postAwsAccError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchGetListOfAccts: () => {
      dispatch(getListOfAccts());
    },
    clearAllOrgAccountMessages: () => {
      dispatch(clearAllOrgAccountMessages());
    },
    getLicensePricingInfo: (accountNumber, subscription, orgCode) => {
      dispatch(getPricingInfo(accountNumber, subscription, orgCode));
    },
    clearAllAccountConfigurationMessages: () => {
      dispatch(clearAllAccountConfigurationMessages());
    },
    clearAllAwsAccMessages: () => {
      dispatch(clearAllAwsAccMessages());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailPolicy);
