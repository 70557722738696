import { del, get, getBodyOnly, post, put } from "./aws_api_helper";
import * as url from "./url_helper";
import axios from "axios";

import accessToken from "../helpers/jwt-token-access/accessToken";
import jwt_decode from "jwt-decode";

const AWS_API_URL = process.env.REACT_APP_AWS_API_URL;

// Get pricing information from AWS
//Updated to include accountNumber
const getPricingInfo = (accountNumber, subscription, orgCode) => {
  console.log("----== getPricingInfo ", url.GET_LICENSE_PRICE_INFO);
  //Updated to include accountNumber
  return get(
    `${url.GET_LICENSE_PRICE_INFO}?accountnumber=${accountNumber}&subscription=${subscription}&orgcode=${orgCode}`
  );
};

const getAssetInsightMetric = (accCodes, orgCode) => {
  // console.log("----== getAssetInsightMetric ", url.GET_METRIC_INFO);
  const accCode = encodeURI(accCodes);
  return get(
    `${url.GET_METRIC_INFO}/assetinsight?accCode=${accCode}&orgCode=${orgCode}`
  );
};

const getComplianceMetric = (accCodes, orgCode) => {
  // console.log("----== getComplianceMetric ", url.GET_METRIC_INFO);
  const accCode = encodeURI(accCodes);
  return get(
    `${url.GET_METRIC_INFO}/compliance?accCode=${accCode}&orgCode=${orgCode}`
  );
};

const getCostInsightMetric = (accCodes, orgCode) => {
  // console.log("----== getCostInsightMetric ", url.GET_METRIC_INFO);
  const accCode = encodeURI(accCodes);
  return get(
    `${url.GET_METRIC_INFO}/costinsight?accCode=${accCode}&orgCode=${orgCode}`
  );
};

const getSecurityPostureMetric = (accCodes, orgCode) => {
  // console.log("----== getSecurityPostureMetric ", url.GET_METRIC_INFO);
  const accCode = encodeURI(accCodes);
  return get(
    // `${url.GET_METRIC_INFO}/security?accCode=${accCode}&orgCode=${orgCode}`
    `${url.GET_METRIC_INFO}/securityMetric?accCode=${accCode}&orgCode=${orgCode}`
  );
};

// Login
// const postLogin = (data) => post(url.POST_LAMBDA_LOGIN, data)
const postLogin = (data) => post(url.POST_LAMBDA_LOGINWITHMFA, data)

// Verify TOTP
const postVerifyTotp = (data) => post(url.POST_LAMBDA_LOGINVERIFYTOTP, data)

// Forget Password
const postForgetPwd = (data) => put(url.POST_LAMBDA_FORGET_PASSWORD, data);

// Change Password
const resetPwd = (data) => put(url.PUT_RESET_PASSWORD, data);

const resetPasswordFrgt = (dto) => {
  return put(url.POST_LAMBDA_RESET_FORGOT_PASSWORD, dto);
};

//Orgs name
const getOrgsName = (data) => {
  console.log("----== getOrgsName ", url.GET_LAMBDA_ORGS_NAME);
  return get(`${url.GET_LAMBDA_ORGS_NAME}`);
};
// Get list of orgs and accounts
const getOrgsAndAccts = (data) => {
  console.log(
    "----== getOrgsAndAccts ",
    url.GET_LAMBDA_LIST_OF_ORGS_AND_ACCOUNTS
  );
  return get(`${url.GET_LAMBDA_LIST_OF_ORGS_AND_ACCOUNTS}`, data);
}
// Get main dashboard data
const getLicensing = () => {
  return get(`${url.GET_LAMBDA_LICENSING}`);
};
const addNewUserPost = (payload) => {
  return post(url.POST_LAMBDA_NEW_USER, payload);
};
const getProfileRoleInfo = () => {
  return get(`${url.GET_LAMBDA_PROFILE_ROLE_INFO}`);
};
const postRegister = (data) => post(url.POST_LAMBDA_REGISTER, data);
const putRegister = data => put(url.PUT_LAMBDA_REGISTER, data)
const getAccRules = () => {
  return get(url.GET_LAMBDA_ACC_RULES);
};
const getAllRules = () => {
  return get(url.GET_LAMBDA_ALL_RULES);
};
const getAwsAccountNumber = () => {
  return get(url.GET_LAMBDA_AWS_ACCOUNT_NUMBER);
};

const postAwsAccountNumber = (data) => {
  return post(url.POST_LAMBDA_AWS_ACCOUNT_NUMBER, data);
};

// HP Actual API Call
const postOrgAccount = (data) => post(url.POST_LAMBDA_ORG_ACCOUNT, data);

const editUserPatch = (payload) => {
  return put(url.PUT_LAMBDA_EDIT_USER, payload);
};
const deleteUserService = (payload) => {
  console.log("--== ** deleteUserService ", payload);
  return put(url.DELETE_LAMBDA_USER, payload);
};
const updateRules = (payload) => {
  return put(url.PUT_LAMBDA_RULES_MAP, payload);
};
const putToggleRulezLambda = (params) => {
  return put(url.PUT_LAMBDA_RULES_MAP, { payload: params });
};
// Update AWS Account
const updateAccount = (data) => put(url.UPDATE_LAMBDA_ORG_ACCOUNT, data);

const getActivateEmail = (data) => post(url.GET_ACCOUNT_ACTIVATION, data);

const reActiveGet = (payload) => {
  console.log("--== ** ReActiveGet ", payload);
  return get(`${url.GET_REACTIVE_USER_LAMBDA}?email=${payload.email}`);
};

const getAccountsAndTabToken = () => {
  console.log("does one exist");
  return get(url.GET_TAB_TOKEN_TABLEAU_LAMBDA);
};

const getTabToken = () => {
  console.log("getTabToken API call");
  return get(url.GET_TAB_TOKEN_TABLEAU_LAMBDA);
};

const validateProspectiveToken = (payload) => {
  console.log("sending validate, " + payload.token);
  return post(url.PUT_VALIDATE_PROSPECTIVE_TOKEN, payload);
};

const addResourceGroup = (data) => {
  console.log("----== addResourceGroup ", url.POST_LAMBDA_RESOURCE_GROUP);
  return post(url.POST_LAMBDA_RESOURCE_GROUP, data);
};

const editResourceGroup = (data) => {
  console.log("----== editResourceGroup ", url.POST_LAMBDA_RESOURCE_GROUP);
  return put(url.POST_LAMBDA_RESOURCE_GROUP, data);
};

const deleteResourceGroup = (resourceGroupName, orgCode) => {
  console.log("----== deleteResourceGroup ", url.POST_LAMBDA_RESOURCE_GROUP);
  const queryParams = `?resourceGroupName=${encodeURIComponent(resourceGroupName)}&orgCode=${encodeURIComponent(String(orgCode))}`;
  console.log("Query Parameters:", queryParams);
  return del(`${url.POST_LAMBDA_RESOURCE_GROUP}${queryParams}`);
};

const getResourceGroups = () => {
  console.log("----== getResourceGroups ", url.GET_LAMBDA_RESOURCE_GROUPS);
  return get(`${url.GET_LAMBDA_RESOURCE_GROUPS}`);
};

const getRuleRemediation = (resourceName) => {
  console.log(
    "----== getResourceGroups ",
    url.GET_RULE_REMEDIATION,
    " requested resource: ",
    resourceName
  );
  // TODO: add resource name
  return get(`${url.GET_RULE_REMEDIATION}?ruleId=${resourceName}`);
};

const getIaacMetrics = (orgcode) => get(`${url.GET_IAAC_METRICS}?orgCode=${orgcode}`);

const getUploadFileUrl = (fileName, orgcode, username, uuid) => {
  const encodedUsername = username.replace(/\+/g, '%2B');
  const lastDotIndex = fileName.lastIndexOf(".");
  let fullFileName;
  if (lastDotIndex === -1) {
    fullFileName = `${fileName}%26${orgcode}%26${encodedUsername}%26${uuid}`

  } else {
    const fileNameWithoutExtension = fileName.slice(0, lastDotIndex);
    const fileExtension = fileName.slice(lastDotIndex + 1);
    fullFileName = `${fileNameWithoutExtension}%26${orgcode}%26${encodedUsername}%26${uuid}.${fileExtension}`
  }

  const apiUrl = `${url.GET_FILE_UPLOAD_URL}?fileName=${fullFileName}`;

  console.log("getUploadFileUrl = ", apiUrl);
  return get(apiUrl);
};

const postUploadFile = (fileData, uploadUrl) => {
  // console.log(`fileData=${fileData} uploadUrl=${uploadUrl}`);

  let contentType = "application/octet-stream"; // Default to a general binary type

  if (fileData.type) {
    contentType = fileData.type;
  }
  // console.log("contentType: ", contentType);

  return axios.put(uploadUrl, fileData, {
    headers: {
      "Content-Type": contentType,
    },
  });
};

const postFileCommitDetails = (
  orgcode,
  filename,
  username,
  author,
  projectName,
  branch,
  version,
  comment,
  uuid
) => {
  console.log(
    `orgcode=${orgcode}, filename=${filename}, username=${username}, author=${author}, projectName=${projectName}, branch=${branch}, version=${version}, comment=${comment} `
  );
  const lastDotIndex = filename.lastIndexOf(".");
  let fullFileName;
  if (lastDotIndex === -1) {
    fullFileName = `${filename}&${orgcode}&${username}&${uuid}`
  } else {
    const fileNameWithoutExtension = filename.slice(0, lastDotIndex);
    const fileExtension = filename.slice(lastDotIndex + 1);
    fullFileName = `${fileNameWithoutExtension}&${orgcode}&${username}&${uuid}.${fileExtension}`
  }
  
  const templateBucketUri = `${orgcode}/${fullFileName}`;
  const commitObject = {
    orgCode: orgcode,
    fileName: filename,
    userName: username,
    author: author,
    projectName: projectName,
    branch: branch,
    version: version,
    comments: comment,
    templateBucketUri,
  };
  return post(url.POST_FILE_COMMIT_DETAILS, commitObject);
};

const getIaacFileHistory = (orgcode) => get(`${url.GET_IAAC_FILE_HISTORY}?orgCode=${orgcode}`);

const getDownloadFile = (fileId, fileType) => {
  console.log(`fileId=${fileId}`);
  const apiUrl = `${url.GET_IAAC_FILE}?fileName=${encodeURIComponent(fileId)}&key=${fileType}`;
  console.log(`Download file apiUrl=${apiUrl}`);
  return get(apiUrl);
};

const getIaacSubscription = (orgcode) => get(`${url.GET_IAAC_SUBSCRIPTION}?orgCode=${orgcode}`);

const postIaacSubscription = (orgcode, subscription) => post(`${url.POST_IAAC_SUBSCRIPTION}?orgCode=${orgcode}`, subscription);

const putIaacSubscription = (orgcode, subscription) => put(`${url.PUT_IAAC_SUBSCRIPTION}?orgCode=${orgcode}`, subscription);

const getIaacLicense = (orgcode) => get(`${url.GET_IAAC_LICENSE}?orgCode=${orgcode}`);

const getReleaseNotes = (key) => get(`${url.GET_RELEASE_NOTES}?key=${key}`);

const getReleaseDetails = (fileName) => get(`${url.GET_RELEASE_DETAILS}?fileName=${fileName}`);

const postSupportEmail = (data) => post(url.POST_SUPPORT_EMAIL, data.emailPayload);

// AWS Marketplace
const addFulfillment = (payload) => {
  console.log("----== addFulfillment ", url.POST_AWS_FULFILLMENT, payload);
  return post(`${url.POST_AWS_FULFILLMENT}?token=${payload.token}`, {email: payload.email});
};

const awsOnboard = (payload) => {
  console.log("----== awsOnboard ", url.POST_AWS_ONBOARD, payload);
  return post(`${url.POST_AWS_ONBOARD}?token=${payload.token}`, payload);
};

const postFeatureConfiguration = (payload) => {
  console.log("----== postFeatureConfiguration ", url.POST_FEATURE_CONFIGURATION, payload);
  return post(url.POST_FEATURE_CONFIGURATION, payload);
}

const postAccountConfiguration = (payload) => {
  console.log("----== postAccountConfiguration ", url.POST_ACCOUNT_CONFIGURATION, payload);
  return put(url.POST_ACCOUNT_CONFIGURATION, payload);
}

const fetchFunctionCount = async (functionName, accountNumber, roleName, externalId, signal) => {
  const token = accessToken();
  let username;
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const authUser = jwt_decode(obj.token);
    username = authUser.sub.charAt(0) + authUser.sub.slice(1);
  }

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "spring.cloud.function.definition": functionName,
      authorization: `Bearer ${token}`,
      username: username,
    },
    body: JSON.stringify({ accountNumber, readOnlyRole: roleName, externalId }),
    signal: signal,
  };

  return await fetch(AWS_API_URL + "/scan", options);
};

const verifyAccountStatus = async (accountNumber, roleName, externalId) => {
  const token = accessToken();
  let username;
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const authUser = jwt_decode(obj.token);
    username = authUser.sub.charAt(0) + authUser.sub.slice(1);
  }

  const options = {
    method: "POST",
    mode: "cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "spring.cloud.function.definition": "VerifyAccountStatus",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
      username: username,
    },
    body: JSON.stringify({ accountNumber, readOnlyRole: roleName, externalId }),
  };

  try {
    const response = await fetch(AWS_API_URL + "/scan", options);
    const responseText = await response.text(); // Read response as plain text

    return {
      status: response.status, // Include HTTP status
      body: responseText,     // Include backend response
    };
  } catch (error) {
    console.error("Error verifying account status:", error);
    return {
      status: 500, // Assign a generic error code for exceptions
      body: error.message, // Return the exception message
    };
  }
};

const verifyBucketPolicyStatus = async (role, accountNumber, bucketName, readOnlyRole, selectedRegion, key, externalId) => {
  const token = accessToken();
  let username;
  if (localStorage.getItem("authUser")) {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const authUser = jwt_decode(obj.token);
    username = authUser.sub.charAt(0) + authUser.sub.slice(1);
  }

  const options = {
    method: "POST",
    url: AWS_API_URL + "/cloudnova/bucketpolicy",
    headers: {
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
      username: username,
    },
    body: JSON.stringify({ role, accountNumber, bucketName, readOnlyRole, selectedRegion, externalId }),
  };
  
  try {
    const response = await fetch(`${AWS_API_URL}/cloudnova/bucketpolicy?key=${key}`, options);
    if (response.ok) {
      const data = await response.text();
      return data;
    } else {
      const data = await response.text();
      throw new Error("Server returned " + data);
    }
  } catch (error) {
    console.log("In verifyBucketPolicyStatus, error: ", error.message);
    return error.message;
  }
};

const fetchKibanaDashboardsApi = (orgcode) => get(`${url.FETCH_KIBANA_DASHBOARDS_URL}?orgCode=${orgcode}`);

const fetchBillingRecordsApi = () => {
  console.log("Billing Api called")
  return get(`${url.FETCH_BILLING_RECORDS_URL}`);
};

// Tag Allocation Cost insight
// Download tags
const fetchTagsApi = (orgCode, accountNumber, username) => {
  console.log("Download Tags Api called");
  return get(`${url.COST_INSIGHT_TAG_ALLOCATION}/download`, {
    params: {
      orgCode: orgCode,
      accountNumber: accountNumber,
      // username: username
    }
  });
};

const fetchTagDetailsApi = (orgCode, accountNumber) => {
  console.log("Get Tag Details API called with:", { orgCode, accountNumber });
  return get(`${url.COST_INSIGHT_TAG_ALLOCATION}/getTagDetails`, {
    params: {
      orgCode: orgCode,
      accountNumber: accountNumber,
    }
  });
};

// const uploadTagsApi = (orgCode, accountNumber, fileContent) => {
//   console.log("#############Upload Tags Api called with orgCode=",orgCode, "accountNumber=",accountNumber, "fileContent=",fileContent);
//   return post(`${url.COST_INSIGHT_TAG_ALLOCATION}/upload?orgCode=${orgCode}&accountNumber=${accountNumber}`, fileContent);
// }
const uploadTagsApi = (orgCode, accountNumber, fileName, formData) => {
  console.log("#############Upload Tags Api called with orgCode=",orgCode, "accountNumber=",accountNumber, "formData=",formData);

  for (let pair of formData.entries()) {
    console.log("uploadTagsApi, pair:",pair[0] + ': ' + pair[1]);
}


  return post(`${url.COST_INSIGHT_TAG_ALLOCATION}/upload?orgCode=${orgCode}&accountNumber=${accountNumber}&filename=${fileName}`, 
    formData, 
    // {
    //   headers: {
    //     "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
    //   },
    // }
  );
}



export {
  getPricingInfo,
  getAssetInsightMetric,
  getComplianceMetric,
  getCostInsightMetric,
  getSecurityPostureMetric,
  postLogin,
  postVerifyTotp,
  postForgetPwd,
  resetPasswordFrgt,
  getOrgsName,
  getOrgsAndAccts,
  getLicensing,
  addNewUserPost,
  getProfileRoleInfo,
  postRegister,
  putRegister,
  getAccRules,
  getAllRules,
  getAwsAccountNumber,
  postAwsAccountNumber,
  postOrgAccount,
  editUserPatch,
  deleteUserService,
  updateRules,
  putToggleRulezLambda,
  updateAccount,
  getActivateEmail,
  getAccountsAndTabToken,
  reActiveGet,
  validateProspectiveToken,
  addResourceGroup,
  editResourceGroup,
  deleteResourceGroup,
  getResourceGroups,
  getTabToken,
  resetPwd,
  getRuleRemediation,
  getIaacMetrics,
  getUploadFileUrl,
  postUploadFile,
  postFileCommitDetails,
  getIaacFileHistory,
  getDownloadFile,
  getIaacSubscription,
  getIaacLicense,
  postIaacSubscription,
  putIaacSubscription,
  getReleaseNotes,
  getReleaseDetails,
  postSupportEmail,
  addFulfillment,
  awsOnboard,
  postFeatureConfiguration,
  postAccountConfiguration,
  fetchFunctionCount,
  verifyBucketPolicyStatus,
  verifyAccountStatus,
  fetchKibanaDashboardsApi,
  fetchBillingRecordsApi,
  fetchTagsApi,
  fetchTagDetailsApi,
  uploadTagsApi
};