import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import {
  fetchFunctionCountsSuccess,
  fetchFunctionCountsFailure,
  updateFunctionCountsProgress,
  fetchFunctionCountsRequest,
  verifyAccountStatusRequest,
  verifyAccountStatusSuccess,
  verifyAccountStatusFailure,
  verifyBucketPolicyStatusRequest,
  verifyBucketPolicyStatusSuccess,
  verifyBucketPolicyStatusFailure,
  clearAllScanServiceMessagesSuccessful,
  clearAllScanServiceMessages,
} from './scanServiceSlice';
import { fetchFunctionCount, verifyAccountStatus, verifyBucketPolicyStatus } from "../../helpers/auth_aws_helper";

function* fetchFunctionCountsSaga({ payload: fetchFunctionCountsPayload }) {
  const { functionNames, accountNumber, roleName, externalId, signal } = fetchFunctionCountsPayload;
  let total = 0;

  try {
    for (let i = 0; i < functionNames.length; i++) {
      const functionName = functionNames[i];
      let success = false;
      let retries = 0;

      while (!success && retries < 3) {
        try {
          const response = yield call(fetchFunctionCount, functionName, accountNumber, roleName, externalId, signal);

          if (response.status === 200) {
            total += 1;
            success = true;
          } else {
            retries++;
            yield call(() => new Promise((resolve) => setTimeout(resolve, 1000))); // Retry delay
          }
        } catch (error) {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted');
            return; // Exit the function if aborted
          } else {
            retries++;
            yield call(() => new Promise((resolve) => setTimeout(resolve, 1000))); // Retry delay
          }
        }
      }

      yield put(updateFunctionCountsProgress(i + 1)); // Update progress after each function
      if (!success) {
        yield put(fetchFunctionCountsFailure({ total, error: 'Failed to fetch all function counts' }));
        return;
      }
    }

    yield put(fetchFunctionCountsSuccess({ total }));
  } catch (error) {
    yield put(fetchFunctionCountsFailure({ total: 0, error: error.message }));
  }
}

function* verifyAccountStatusSaga({ payload: verifyAccountStatusPayload }) {
  const { accountNumber, roleName, externalId } = verifyAccountStatusPayload;

  try {
    const result = yield call(verifyAccountStatus, accountNumber, roleName, externalId);
    console.log("Result in Saga ::: ", result)
    console.log("Result Status :: ", result.status)
    if (result.status === 200) {
      // For HTTP 200, dispatch success with the backend response
      yield put(verifyAccountStatusSuccess(result.body));
    } else {
      // For non-200, dispatch failure with the backend response
      yield put(verifyAccountStatusFailure(result.body));
    }
  } catch (error) {
    yield put(verifyAccountStatusFailure(error.message));
  }
}

function* verifyBucketPolicyStatusSaga({ payload: verifyBucketPolicyPayload }) {
  const { role, accountNumber, bucketName, readOnlyRole, selectedRegion, key, externalId } = verifyBucketPolicyPayload;

  try {
    const result = yield call(verifyBucketPolicyStatus, role, accountNumber, bucketName, readOnlyRole, selectedRegion, key, externalId);
    yield put(verifyBucketPolicyStatusSuccess(result));
  } catch (error) {
    yield put(verifyBucketPolicyStatusFailure(error.message));
  }
}

function* clearAllScanServiceMessagesSaga() {
  console.log("Saga: clear All ScanService Messages");
  yield put(clearAllScanServiceMessagesSuccessful());
}

export function* watchScanService() {
  yield takeLatest(fetchFunctionCountsRequest.type, fetchFunctionCountsSaga);
  yield takeLatest(verifyAccountStatusRequest.type, verifyAccountStatusSaga);
  yield takeLatest(verifyBucketPolicyStatusRequest.type, verifyBucketPolicyStatusSaga);
  yield takeLatest(clearAllScanServiceMessages.type, clearAllScanServiceMessagesSaga);
}

export function* scanServiceSaga() {
  yield all([fork(watchScanService)]);
}

export default scanServiceSaga;